@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    @apply font-sans;
  }
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Roboto:wght@100&display=swap");

html {
  scroll-behavior: smooth;
}

#results-root {
  min-height: calc(100vh - 400px);
}

/* NAVIGATION */

#header {
  position: relative;
  z-index: 9999;
}

.logo-wrap {
  position: relative;
  width: 130px;
  cursor: pointer;
}

.version {
  font-size: 0.65rem;
  color: #b7b7b7;
  position: absolute;
  right: 3px;
  top: 43px;
  font-style: italic;
}

#pages-nav {
  top: 80px;
  right: 20px;
  width: 0;
  overflow: hidden;
  text-align: left;
  position: absolute;
  background: #ea580c;
  max-width: 300px;
  border-radius: 10px;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: all 0.5s ease-out;
}

#pages-nav.show-menu {
  width: 100%;
}

.nav-icon {
  display: inline-block;
  margin: 0 20px;
  font-size: 30px;
}

#pages-nav a {
  display: block;
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #f78347;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  min-width: max-content;
}

#pages-nav a:hover {
  color: #ea580c;
}

#pages-nav.show-menu a:hover {
  color: #000;
}

#pages-nav a svg {
  display: inline-block;
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

#pages-nav a.acc-icon {
  display: none;
  position: relative;
}

#pages-nav a.acc-icon:hover {
  color: #ea580c;
}

.register-nav {
  display: block;
  padding-right: 20px;
}

#pages-nav .register-nav a {
  background: #ea580c;
  color: #fff;
  border-radius: 0;
  display: block;
}

#pages-nav .register-nav a:hover {
  background: #000;
}

#pages-nav a.active {
  color: #fff;
  border-bottom: 3px solid #fff;
}

.membership {
  position: absolute;
  bottom: 0;
  background: #ea580c;
  color: #fff;
  padding: 3px 5px;
  font-size: 0.5rem;
  line-height: 100%;
  border-radius: 100px;
  left: 16px;
  width: 40px;
  text-align: center;
}

@media screen and (min-width: 1199px) {
  #pages-nav {
    text-align: right;
    position: relative;
    background: transparent;
    max-width: 100%;
    width: 100%;
    top: 0;
    right: 0;
    --tw-shadow: 0;
    --tw-shadow-colored: 0;
    box-shadow: none;
  }

  .nav-icon {
    display: none;
  }

  #pages-nav a {
    display: inline-block;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #000;
  }

  #pages-nav a.acc-icon {
    display: inline-block;
  }

  .register-nav {
    display: inline-block;
  }

  #pages-nav .register-nav a {
    border-radius: 100px;
    display: inline;
  }

  #pages-nav a.active {
    color: #ea580c;
    border-bottom: 3px solid #ea580c;
  }
}

/* SEARCH BAR */
.drop-selector {
  background: #ffffff;
  width: 14rem;
  position: absolute;
  left: 0;
  top: 50px;
  right: auto;
  z-index: 999;
  border: 1px solid #e7e7e7;
  margin-top: 0.5rem;
  box-shadow: 0 0 15px 0 #f5f5f5;
}

.sb {
  max-width: 64rem;
  margin: 3rem auto 2rem;
}

.ms {
  display: flex;
  flex-wrap: wrap;
}

.ms > div {
  padding: 0.5rem;
}

.ms-icon {
  font-size: 1rem;
  color: #ea580c;
  display: inline-block;
  cursor: pointer;
  margin-top: -3px;
}

.active .ms-icon {
  color: #fff;
}

.ms-icon:hover {
  color: #333;
}

.sf {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  border: 1px solid #ea580c;
}

.sf-1 {
  flex-grow: 1;
  border-bottom: 1px solid #ea580c;
}

.sf-2 {
  min-width: max-content;
  width: 100%;
  position: relative;
}

.sf-2 button {
  width: 100%;
  text-align: left;
  border: none;
}

.sf-2 svg {
  float: right;
  margin: 2px 0 0 10px;
}

.sf-3,
.sf-3 input {
  width: 100%;
  flex-shrink: 0;
}

.search-btn.inactive {
  background: #cecece;
  color: #000;
}

.search-btn.active {
  background: #ea580c;
  color: #fff;
  cursor: pointer;
}

.search-btn {
  letter-spacing: 0.025em;
  padding: 1rem 2.5rem;
  flex-grow: 1;
}

.search-btn.active:hover {
  background: #000;
}

.cs-entries.flex.flex-wrap {
  flex-wrap: wrap;
  display: flex;
}

.dropdown-entry {
  font-size: 0.875rem;
  text-transform: capitalize;
  color: #000;
  justify-content: space-between;
  cursor: pointer;
  display: flex;
  line-height: 100%;
  padding: 15px 15px;
}

.dropdown-entry.active,
.dropdown-entry:hover {
  background: #f2f2f2;
  color: #ea580c;
}

.dropdown-entry.active svg {
  color: #ea580c;
  position: relative;
}

.cs-entries {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  max-height: 400px;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
}

.min.cs-entry {
  width: 100%;
}

.max.cs-entry {
  width: 100%;
}

.cs-search {
  padding: 10px;
  background: #e2e8f0;
}

.cs-search input {
  padding: 10px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 0.8rem;
}

.cs-all-country {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 10px;
  background: black;
  color: #fff;
  cursor: pointer;
}

@media screen and (min-width: 499px) {
  .sf-1 {
    border: none;
  }
  .sf-2 {
    width: 30%;
    border-left: 1px solid #ea580c;
  }
  .ms > div {
    padding: 0.5rem 1rem;
  }
}

@media screen and (min-width: 700px) {
  .ms > div {
    padding: 0.75rem 2rem;
  }
  .sf {
    display: flex;
    flex-wrap: nowrap;
  }
  .sf > div {
    width: auto;
  }
}

/* Expand Mode */
.expand.sf {
  flex-wrap: wrap;
}
.expand .sf-1 {
  width: 100%;
  border-bottom: 1px solid #ea580c;
}

.expand .sf-2 {
  width: 100%;
  border: none;
}

.sf-4 {
  border-bottom: 1px solid #ea580c;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 115px;
  width: 100%;
}

.dropdown-btn {
  width: 100%;
  text-align: left;
  border: none;
  text-transform: capitalize;
  font-size: 0.875rem;
  color: #777;
  padding: 1rem 10px 1rem 20px;
  height: 100%;
}

.dropdown-btn svg {
  float: right;
  margin: 3px 0 0 10px;
  color: #6e8392;
}

@media screen and (min-width: 520px) {
  .expand .sf-2,
  .expand .sf-4 {
    width: 50%;
    border: none;
  }

  .expand .sf-2 {
    border-left: 1px solid #ea580c;
  }

  .expand .sf-1 {
    width: 100%;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 680px) {
  .expand .sf-2,
  .expand .sf-4 {
    width: 50%;
    border: none;
  }

  .expand .sf-2 {
    border-left: 1px solid #ea580c;
  }

  .expand .sf-1,
  .expand .sf-3 {
    width: 100%;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 900px) {
  .expand .sf-1,
  .expand .sf-2,
  .expand .sf-3,
  .expand .sf-4 {
    width: auto;
    border: none;
  }

  .expand .sf-2,
  .expand .sf-4 {
    border-left: 1px solid #ea580c;
  }
}

/* COMMON BUTTON */
.more-btn-wrap {
  text-align: center;
  padding: 3rem 1rem 1rem;
}

a.more-btn {
  background: #ea580c;
  color: #fff;
  padding: 10px 30px;
  display: inline-block;
}

a.more-btn:hover {
  background: #000;
}

/* LOADER */

#niche-loader {
  text-align: center;
  padding: 150px 0;
  width: 100%;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:before {
  content: "";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #f05223;
  border-color: #f05223 transparent #f05223 transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

/* Loader 3 dots */
.spinner {
  margin: 30px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Mini Loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}
.lds-ellipsis div {
  position: absolute;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f4f4f4;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2e8f0;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f172a;
  background: #ea580c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ea580c;
  background: #0f172a;
}

#progress-bar {
  -webkit-transition: width 500ms ease;
  -moz-transition: width 500ms ease;
  -ms-transition: width 500ms ease;
  -o-transition: width 500ms ease;
  transition: width 500ms ease;
}

/*  LOGIN  */
#login {
  background: #e2e8f0;
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
}

.login-wrap {
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.form-wrap {
  width: 100%;
  flex-shrink: 0;
  padding: 1.25rem 2rem;
}

img.fw-a {
  width: 9rem;
  margin-bottom: 4rem;
}

.fw-b {
  color: #ea580c;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.fw-form {
  margin-bottom: 1rem;
}

.fw-form [type="text"],
.fw-form [type="password"] {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.75rem 1.25rem;
  background: #f1f5f9;
  width: 100%;
  margin-bottom: 1rem;
}

.fw-form #submit-btn {
  background: #ea580c;
  text-transform: uppercase;
  padding: 0.75rem 1rem;
  cursor: pointer;
  width: 100%;
  color: #fff;
}

.fw-form #submit-btn:hover {
  background-color: #000;
}

.fw-info {
  text-align: center;
  font-size: 0.825rem;
  line-height: 1.2rem;
  background: #ffffd6;
  border: 1px solid yellow;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.fw-error,
.fw-success {
  color: #8b0000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 0.75rem;
  margin-bottom: 1.25rem;
}

.fw-error svg,
.fw-success svg {
  display: inline-block;
  margin-right: 0.5rem;
}

.fw-success {
  color: #006400;
}

.fw-d {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.75rem;
  color: #ea580c;
  cursor: pointer;
}

.fw-d:hover {
  color: #000;
}

.fw-e {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}

.fw-f {
  color: #ea580c;
}

.fw-f:hover {
  color: #000;
}

.login-b {
  background-image: url("./images/robot.svg");
  background-repeat: no-repeat;

  background-size: contain;

  background-position: right 60px;
  flex-shrink: 0;
  width: 100%;
  background-color: #ea580c;
  min-height: 400px;
}

.login-b-1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: #fff;
  padding-left: 1.5rem;
  margin-top: 1.5rem;
}

.login-b-2 {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
}

.login-b-2:hover {
  color: #000;
}

@media screen and (min-width: 519px) {
  img.fw-a {
    margin-bottom: 9rem;
  }

  .login-b {
    background-size: 50%;
    background-position: right 50px;
  }

  .login-b-1 {
    padding-left: 3rem;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .form-wrap,
  .login-b {
    width: 50%;
  }

  .login-b {
    background-size: 100%;
    background-position: 0 95px;
  }
}

/* LOGOUT */
#logout {
  background: #ea580c;
  align-items: center;
  width: 100%;
  display: flex;
  height: 100vh;
}

.logout-wrap {
  color: #fff;
  font-size: 1.875rem;
  line-height: 2.25rem;
  max-width: 32rem;
  margin: 0 auto;
  text-align: center;
}

.bot-wrap {
  width: 100%;
  height: 300px;
  max-width: 300px;
  margin: 0 auto;
  background-image: url("./images/robot.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
}

.bot-message {
  padding: 0 20px;
}

@media screen and (max-width: 479px) {
  .logout-wrap {
    color: #fff;

    font-size: 1.2rem;
    line-height: 1.5rem;
    max-width: 32rem;
    margin: 0 auto;
    text-align: center;
  }
}

/* ANALYZE */
#analyze-1 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

#keyword-data {
  width: 100%;
  margin-bottom: 40px;
}

.kd {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.2rem;
}

.kd-1,
.kd-2,
.kd-3 {
  flex-grow: 1;
  text-align: center;
  width: 33.33%;
}

.kd-1-a,
.kd-2-a {
  font-size: 3rem;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 1rem;
}

.kd-3-a {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 5px;
  line-height: 100%;
}

.kd-3-a .difficulty {
  padding: 10px 15px;
}

#keyword-ideas {
  margin-left: 0;
  width: 100%;
}

.ndd-d {
  display: block;
}

#keyword-ideas #ks-entries {
  max-height: 375px;
}

.fr-btn {
  display: block;
  margin: 2rem auto;
  text-align: center;
  max-width: 400px;
  background: #ea580c;
  padding: 1.5rem 1rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.fr-btn:hover {
  background: #000;
}

@media screen and (max-width: 479px) {
  .kd-1-a,
  .kd-2-a {
    font-size: 2rem;
  }

  .kd-1,
  .kd-2 {
    font-size: 0.75rem;
  }
}

/* Niche Drill Down */
.ndd-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.ndd-e {
  width: 5rem;
  flex-shrink: 0;
}

.ndd-f {
  flex-grow: 1;
}
.ndd-g {
  width: 5rem;
  flex-shrink: 0;
}

.ndd-h {
  width: 5rem;
  flex-shrink: 0;
}
#ndd-entries {
  max-height: 350px;
}

.ndd-entry {
  display: flex;
  flex-wrap: nowrap;
  min-height: 50px;
}

.ndd-a {
  width: 5rem;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0.25rem 0;
  height: 2.5rem;
}

.ndd-b {
  flex-grow: 1;
  padding-right: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.ndd-b svg {
  width: 1rem;
  flex-shrink: 0;
}

.ndd-c {
  width: 5rem;
  flex-shrink: 0;
}

.ndd-d {
  width: 4rem;
  flex-shrink: 0;
  display: block;
}

@media screen and (min-width: 991px) {
  #keyword-data {
    width: calc(100% - 430px);
  }

  #keyword-ideas {
    width: 420px;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .ndd-d,
  .ndd-h {
    display: none;
  }

  .ndd-c {
    width: 4rem;
  }
}

@media screen and (max-width: 479px) {
  .ndd-d,
  .ndd-h,
  .ndd-e,
  .ndd-a {
    display: none;
  }
}

/* Serp */
#serp {
  margin-bottom: 3rem;
}

.serp-a {
  display: flex;
  flex-wrap: nowrap;
  font-weight: bold;
  color: rgb(234, 88, 12);
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  font-size: 12px;
  align-items: center;
  border-bottom: 4px solid #e2e8f0;
  padding-bottom: 0.8rem;
}

.serp-b {
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.85rem;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  min-height: 50px;
}

.serp-1 {
  width: 64px;
  text-align: center;
}

.serp-2 {
  flex-shrink: 0;
  width: 50%;
  padding: 5px 0;
}

.serp-bread {
  font-size: 0.75rem;
  color: #707070;
}

.serp-title {
  display: inline-block;
  color: mediumblue;
}

.serp-3,
.serp-4,
.serp-5 {
  width: calc(25% - 32px);
}

.serp-6 {
  width: 15%;
}

.serp-6 div div:first-child {
  width: 30px;
  flex-shrink: 0;
}

@media screen and (max-width: 759px) {
  .serp-a {
    display: none;
  }

  .serp-b {
    flex-wrap: wrap;
    padding: 10px;
    border: none;
    border-top: 4px solid #e2e8f0;
  }

  .serp-b:nth-child(odd) {
    background-color: #f5f9ff;
  }

  .serp-b .serp-1 {
    display: none;
  }

  .serp-b .serp-2 {
    width: 100%;
    font-size: 1.1rem;
  }

  .serp-b .serp-3:before {
    content: "Ranked Keyword";
    font-weight: normal;
    color: #f05223;
    display: block;
  }

  .serp-b .serp-4:before {
    content: "Traffic";
    font-weight: normal;
    color: #f05223;
    display: block;
  }
  .serp-b .serp-5:before {
    content: "Backlinks";
    font-weight: normal;
    color: #f05223;
    display: block;
  }

  .serp-b .serp-6:before {
    content: "Open Page Rank";
    font-weight: normal;
    color: #f05223;
    display: block;
  }

  .serp-3,
  .serp-4,
  .serp-5,
  .serp-6 {
    width: auto;
    flex-grow: 1;
    font-weight: bold;
    min-width: 120px;
    margin-bottom: 10px;
  }
}

/* Analyze floating nav */
#analyze-nav {
  top: 150px;
  position: fixed;
  right: 20px;
}

#analyze-nav a {
  padding: 10px;
  color: #838d99;
  display: block;
  border-radius: 100px;
}

#analyze-nav a.active,
#analyze-nav a.active:hover {
  background-color: #f97316;
  color: #fff;
}

#analyze-nav a:hover {
  color: #f97316;
}

#analyze-nav a > * {
  pointer-events: none;
}

@media screen and (max-width: 1379px) {
  #analyze-nav {
    display: none;
  }
}

/* Additional Pages */
#training {
  width: 800px;
  max-width: 100%;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border: 1px solid #e2e8f0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#t-nav {
  display: flex;
  flex-wrap: wrap;
}

#t-nav .active,
#t-nav .inactive:hover {
  background: #334155;
  border-top: 8px solid #ea580c;
}

#t-nav .inactive {
  background: #e2e8f0;
  border-top: 8px solid #cbd5e1;
  cursor: pointer;
}

#t-nav > div {
  flex-grow: 1;
  padding: 1rem;
}

.t-a {
  display: block;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: #a8a8a8;
}

.t-b {
  font-size: 1rem;
  color: #ea580c;
}

/* Support */
#support {
  min-height: calc(100vh - 100px);
}

#bot-form {
  background-image: url("./images/robot.svg");
  background-repeat: no-repeat;
  background-size: 260px;
  background-position: center 10px;
  min-height: 240px;
}

#bot-failed {
  background-image: url("./images/robot.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center 75px;
  min-height: 400px;
}

.sp-a {
  max-width: 42rem;
  margin: 0 auto;
}

.sp-b {
  max-width: 32rem;
  margin: 0 auto;
}

div#support .warn {
  background: #dc2626;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 1.25rem 3rem;
  border-radius: 9999px;
  align-items: center;
  display: flex;
  color: #fff;
  max-width: 32rem;
  margin: 0 auto 1.5rem;
}

div#support #bot-failed {
  max-width: 35rem;
  margin: 0 auto;
}

.w-a {
  font-size: 3.75rem;
  line-height: 1;
  width: 5rem;
  flex-shrink: 0;
}

.w-b {
  text-align: center;
  max-width: 24rem;
  margin: 0 auto;
}

.w-b a {
  color: #ea580c;
}

.w-b a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 579px) {
  #support #bot-failed {
    max-width: 35rem;
    margin: 0 auto;
    background-size: 375px auto;
    background-position: center;
  }
  #support .w-a {
    display: none;
  }
}

/* Tooltip */
.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  position: absolute;
  left: 88px;
}

.tooltip.hide {
  display: none !important;
}

.tooltip,
.tooltip:focus-visible {
  position: absolute;
  z-index: 999;
  left: -90px;
  min-width: 200px;
  text-transform: none;
  font-size: 12px;
  text-align: left;
  font-weight: normal;
  outline: none;
}

.tt-header {
  color: #fff;
  background: #000;
  padding: 5px 10px;
  margin-top: 10px;
  text-transform: capitalize;
}

.tt-body,
.tt-body:focus-visible {
  padding: 10px 15px;
  color: #000;
  background: #ffedd5;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 8px 0 #e6e6e6;
  box-shadow: 0 0 8px 0 #e6e6e6;
  outline: none;
  line-height: 160%;
}

.tt-close {
  cursor: pointer;
  float: right;
  background: #363636;
  margin-right: -10px;
  margin-top: -5px;
  width: 26px;
  height: 100%;
  padding: 5px;
}

.tt-close:hover {
  background: #ea580c;
}

/* Account Side Menu */
#acc-side-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  max-width: 450px;
  padding: 20px;
  border-left: 1px solid #ededed;
  z-index: 9999999;
  min-height: 100vh;
  overflow: hidden;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

#acc-side-menu.hide {
  max-width: 0;
  padding: 20px 0;
  border: none;
}

.close-menu {
  display: inline-block;
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 50px;
  background: #000;
  color: #fff;
  cursor: pointer;
}

.close-menu:hover {
  background: #ea580c;
}

/* Section Header */
.section-header {
  background: #e2e8f0;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.5rem 1.25rem;
  color: #475569;
  text-transform: uppercase;
}

.tt-wrap {
  display: inline-block;
  margin-left: 0.75rem;
  position: relative;
}

/* FOOTER */
.footer-nav li {
  display: inline-block;
  margin: 10px 5px;
}

.footer-nav li a {
  color: #ea580c;
}

.footer-nav li a:hover {
  color: #000;
}

/* ================ ACCOUNT PAGE ================= */
.pdf-entry {
  margin-bottom: 1rem;
}

.pdf-img-wrap {
  display: inline-block;
}

#pdf-img {
  max-width: 100%;
  overflow: hidden;
  word-break: break-all;
  max-height: 150px;
  border: 1px solid #e2e8f0;
  padding: 20px;
  display: inline-block;
  cursor: pointer;
}

.pdf-label {
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 0;
  color: #f05223;
}

#pdf-blurb,
#pdf-footer {
  width: 100%;
  padding: 15px;
  border: 1px solid #e2e8f0;
}

.pdf-description {
  font-size: 12px;
  color: #999;
  font-style: italic;
}

.btn-wrap > div {
  display: inline-block;
  padding: 10px 20px;
  background: #f05223;
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
}

.btn-wrap {
  text-align: center;
  margin: 30px 0;
}

.btn-wrap > div:hover {
  background: #000;
}

span.pdf-info {
  display: block;
  padding: 20px;
  color: red;
  background: yellow;
  margin-top: 20px;
}

/* Saved Niche */
#saved-niche {
  margin-bottom: 3rem;
}

.sn-entry {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.sn-entry:hover {
  background: #f5f9ff;
}

.sn-action {
  cursor: pointer;
  color: #ea580c;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  width: 14px;
}

.sn-action:hover {
  color: #000;
}

.sn-keyword {
  flex-grow: 1;
  text-transform: capitalize;
}

.sn-delete {
  cursor: pointer;
  color: #cdcdcd;
  border-radius: 100px;
  font-size: 0.75rem;
  display: inline-block;
  margin-left: 20px;
  text-align: right;
  padding: 0 5px;
}

.sn-entry:hover .sn-delete {
  background: #f05223;
  color: #fff;
}

/* Search History */
#search-history {
  margin-bottom: 3rem;
}

.sh-entry {
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: inline-block;
  text-transform: lowercase;
}

.sh-1 {
  color: #f05223;
  font-size: 0.75rem;
  line-height: 1rem;
  display: inline-block;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

/* ================ RESULT PAGE ================== */
/* HEADLINE RESULT TEXT */
.result-header {
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}

.result-header span {
  font-weight: bold;
  color: #ea580c;
}

@media screen and (max-width: 519px) {
  .result-header {
    font-size: 1.2rem;
  }
}

/* External Link Icon */
.ext-link {
  color: #ea580c;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.5rem;
  display: inline;
  border: none;
}

.ext-link:hover {
  color: #000;
}

/* Download Button */
#download-btn {
  text-align: center;
  width: 100%;
  background: #ea580c;
  color: #fff;
  padding: 1rem 2rem;
  max-width: 250px;
  margin: 3rem auto;
  cursor: pointer;
  text-transform: uppercase;
}

#download-btn:hover {
  background-color: #000;
}

#download-btn.disabled {
  margin-bottom: 10px;
  cursor: auto;
  background: #b1b1b1;
}

.download-help {
  text-align: center;
  padding: 10px 20px;
  font-size: 0.9rem;
  background: yellow;
  max-width: 600px;
  margin: 0 auto;
}

.download-btn-wrapper {
  margin-bottom: 3rem;
}

#download-btn.disabled:hover {
  background: #b1b1b1;
}

/* FAVORITES Button*/
#favorites {
  text-align: center;
  max-width: max-content;
  margin: 0 auto 25px;
  background: #ea580c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

#favorites svg {
  margin-right: 10px;
}

#favorites:hover {
  background-color: #000;
}

/* Star Rating */
.star-rating {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #f97316;
}

/* DISCOVER */
#niche-dir {
  display: flex;
  flex-wrap: wrap;
}

.main-cats {
  width: 100%;
  flex-shrink: 0;
  padding: 15px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbe1e7;
  cursor: pointer;
}

.main-cats:hover {
  background-color: #f3f3f3;
}

.niches-count {
  text-align: center;
  font-size: 1.25rem;
  font-weight: normal;
  margin-top: -25px;
  margin-bottom: 25px;
}

.niches-count span {
  font-weight: bold;
  color: #ea580c;
}

.cat-icon {
  width: 60px;
  text-align: center;
}

.cat-item-count {
  display: block;
  font-size: 0.75rem;
  color: #ea580c;
}

.cat-name {
  font-size: 1rem;
}

.dn {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 0.5rem;
  color: #ea580c;
}

.dn:hover {
  color: #000;
}

.niche-item svg:last-child {
  margin-right: 10px;
}

.niche-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  vertical-align: middle;
}

.niche-item {
  width: 100%;
  font-size: 0.875rem;
  min-height: 50px;
  border-bottom: 1px solid #e2e8f0;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  padding: 0 20px;
}

.niche-item svg {
  margin: 0 5px;
  color: #ea580c;
  cursor: pointer;
}

.niche-item svg:hover {
  color: #000;
}

.niche-count {
  color: #ea580c;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-left: 1rem;
}

.niche-item-cat {
  cursor: pointer;
}

.niche-item-cat:hover {
  background: #f2f2f2;
}

@media screen and (min-width: 519px) {
  .niche-item,
  .main-cats {
    width: 50%;
  }

  .main-cats {
    padding: 30px 0;
    border: none;
  }
}

@media screen and (min-width: 768px) {
  .niche-item,
  .main-cats {
    width: 33.33%;
  }
}

@media screen and (min-width: 991px) {
  .main-cats {
    width: 25%;
  }
}

/* ================ ASK ================== */
/* Qoura */
#quora {
  margin-bottom: 3rem;
}

.q-entry {
  border: none;
  border-bottom: 1px solid #e2e8f0;
  padding: 0.5rem 0;
  display: flex;
}

.q-a {
  width: 2.25rem;
  text-align: center;
  font-weight: bold;
  flex-shrink: 0;
}

.q-b {
  flex-grow: 1;
}

.q-b a {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* Google Related Questions */
.grq-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ea580c;
  border: none;
  border-bottom: 4px solid #e2e8f0;
}

.grq-header svg {
  display: inline-block;
  margin-right: 0.5rem;
}

.grq-entry {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
}

.grq-entry svg {
  margin-right: 5px;
  color: #ea580c;
  width: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.grq-entry svg:hover {
  color: #000;
}

.grq-entries {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;
  min-width: 250px;
  display: inline-block;
  vertical-align: top;
}

@media screen and (min-width: 599px) {
  .grq-entries {
    max-width: 50%;
  }
}

@media screen and (min-width: 899px) {
  .grq-entries {
    max-width: 33.33%;
  }
}

/* ====================EXPAND ================ */
/* Keywords */
.ks-header {
  display: flex;
  color: #ea580c;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  flex-wrap: nowrap;
  height: 30px;
  align-items: center;
  max-width: calc(100% - 10px);
}

.ks-entry {
  min-height: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  border-bottom: 1px solid #e2e8f0;
  line-height: 120%;
}

.ks-entry:hover,
.ks-entry.active {
  background: #f1f5f9;
}

.ks-header .k-a {
  padding: 0;
  height: auto;
  text-align: center;
}

.k-a {
  display: none;
  width: 5rem;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0.25rem 0;
  height: 2.5rem;
}

.k-b {
  flex-grow: 1;
  padding: 0 0.5rem;
  width: 100%;
}

.ks-entry .k-b svg {
  display: inline-block;
  margin-right: 8px;
  font-size: 0.8rem;
  color: #ea580c;
  cursor: pointer;
}

.k-c,
.k-d {
  pointer-events: none;
}

.k-c,
.k-d,
.k-e {
  width: 50%;
  flex-shrink: 0;
}

.ks-header .k-b:hover,
.ks-header .k-c:hover,
.ks-header .k-d:hover,
.ks-header .k-e:hover {
  cursor: pointer;
  color: #000;
}

.k-c:before {
  content: "SV : ";
  color: #ea580c;
  margin-left: 74px;
}

.k-d:before {
  content: "CPC : ";
  color: #ea580c;
}

/* ============== Graph */
.g-wrap {
  display: block;
  text-align: center;
}

.g-a-1 {
  height: 12rem;
}

.g-b,
.g-a {
  width: 100%;
  margin-bottom: 2rem;
}

@media screen and (min-width: 540px) {
  .ks-header {
    display: flex;
  }

  .ks-entry {
    flex-wrap: nowrap;
    min-height: 50px;
  }

  .k-b {
    width: auto;
  }
  .k-c,
  .k-d {
    width: 5rem;
  }
  .k-c:before,
  .k-d:before {
    content: "";
    margin-left: 0;
  }
}

@media screen and (min-width: 640px) {
  .k-a {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .k-c,
  .k-d,
  .k-e {
    width: 8rem;
  }
}

@media screen and (min-width: 1024px) {
  .g-wrap {
    display: flex;
    flex-wrap: nowrap;
  }

  .g-a {
    width: 55%;
    flex-shrink: 0;
    margin-bottom: 0;
  }

  .g-b {
    width: 45%;
    margin-bottom: 0;
  }
}

/* ANALYZE ==================== */
#serp {
  margin-bottom: 3rem;
}

/* Open Page Rank */
.opr {
  background: #e2e8f0;
  height: 15px;

  margin-left: 10px;
  width: 101%;

  position: relative;
  display: inline-block;
  top: 3px;
  border-radius: 50px;
}
.opr span {
  display: block;
  background: #f05223;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 50px;
}

/* YOUTUBE */
#youtube {
  display: flex;
  flex-wrap: wrap;
}

.yt-a,
.yt-b {
  margin: 0;
  width: 100%;
  flex-shrink: 0;
}

.yt-a-1 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
}

.yt-a-1-1 {
  width: 100%;
  text-align: center;
}

.yt-b .yt-a-1-1 {
  max-width: 100px;
}

.yt-a-1-1 img {
  width: 100%;
  max-width: 350px;
  display: inline-block;
  margin-bottom: 1rem;
}

.yt-title {
  font-weight: bold;
  font-size: 0.85rem;
  margin-bottom: 5px;
}

.yt-stat svg {
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  color: #f05223;
}

.yt-stat {
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
}

.yt-stat span {
  margin-right: 0.75rem;
}

.yt-desc {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

@media screen and (min-width: 899px) {
  #youtube {
    flex-wrap: nowrap;
  }
  .yt-a {
    padding-right: 10px;
    width: 50%;
  }

  .yt-b {
    padding-left: 10px;
    width: 50%;
  }
}

@media screen and (min-width: 499px) {
  .yt-a-1 {
    flex-wrap: nowrap;
  }
  .yt-a-1-1 {
    width: 180px;
    text-align: center;
    flex-shrink: 0;
    margin-right: 15px;
  }

  .yt-a-1-1 img {
    margin-bottom: 0;
  }
}

/* Twitter */
#twitter {
  margin-bottom: 3rem;
}

.tw-wrap {
  display: flex;
  flex-wrap: wrap;
}

.tw-entries {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.tw-entry {
  display: flex;
  flex-wrap: nowrap;
  border: none;
  border-bottom: 1px solid #e2e8f0;
}

.tw-a {
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  width: 70px;
  flex-shrink: 0;
}

.tw-a img {
  border-radius: 9999px;
  width: 100%;
  height: auto;
}

.tw-b {
  flex-grow: 1;
  margin-bottom: 0.75rem;
}
.tw-author {
  margin-bottom: 0.7rem;
}

.tw-author span {
  color: rgb(14 165 233);
}

@media screen and (min-width: 699px) {
  .tw-entries {
    width: 50%;
  }
}

/* Reddit */
#reddit {
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 3rem;
}

.red-1 {
  width: 100%;
  margin-bottom: 2rem;
}

.red-2 {
  width: 100%;
}

.red-entry {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem 0.9rem;
  border: none;
  border-bottom: 1px solid #e2e8f0;
}

.red-title {
  margin-bottom: 0.25rem;
}

.red-title a {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.red-a {
  margin-right: 0.5rem;
}

.red-b {
  font-style: italic;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #404040;
}

.red-stat {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}
.sub-dir {
  margin-right: 0.5rem;
  color: #18a3ff;
}

.sub-count,
.sub-member {
  color: #ea580c;
}

.red-blue {
  color: #18a3ff;
}

.red-desc {
  font-size: 0.9rem;
  line-height: 1.2rem;
}

@media screen and (min-width: 699px) {
  .red-1,
  .red-2 {
    width: 50%;
  }

  .red-1 {
    padding-right: 10px;
  }

  .red-2 {
    padding-left: 10px;
  }
}

/* Facebook */
#fb {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

#fb-g,
#fb-p {
  width: 100%;
  padding: 0;
  margin-bottom: 2rem;
}

#fb-g p,
#fb-p p {
  text-align: center;
  max-width: 300px;
  margin: 0 auto 20px;
}

a.btn-2 {
  max-width: 300px;
  display: block;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  color: #ea580c;
  border: 1px solid #ea580c;
  padding: 0.5rem;
}

a.btn-2:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}

@media screen and (min-width: 699px) {
  #fb-g {
    width: 50%;
    padding-right: 10px;
    margin-bottom: 0;
  }

  #fb-p {
    width: 50%;
    padding-left: 10px;
    margin-bottom: 0;
  }
  #fb {
    flex-wrap: nowrap;
    margin-bottom: 3rem;
  }
}

/* Itunes */
#itunes {
  flex-wrap: wrap;
  display: flex;
}

.it-podcast {
  width: 100%;
  padding-right: 0;
}

.it-apps {
  width: 100%;
  padding-left: 0;
}

.it-entry {
  font-size: 0.75rem;
  line-height: 1rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  flex-wrap: nowrap;
}

.it-a {
  width: 100px;
  flex-shrink: 0;
  padding-right: 10px;
}

.it-a img {
  width: 100%;
}

.it-b-1 {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
}

.it-b-2 {
  margin-bottom: 0.5rem;
  color: #f05223;
}

.it-b-3 {
  font-size: 0.9rem;
  line-height: 1.2rem;
}

@media screen and (min-width: 799px) {
  #itunes {
    flex-wrap: nowrap;
  }

  .it-podcast {
    width: 50%;
    padding-right: 10px;
  }

  .it-apps {
    width: 50%;
    padding-left: 10px;
  }
}

/* Amazon / Google */
#amazon,
#google {
  margin-bottom: 3rem;
}

.m-header {
  text-transform: uppercase;
  flex-wrap: nowrap;
  display: flex;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #f97316;
  align-items: center;
  border: none;
  border-bottom: 4px solid #e2e8f0;
  padding: 0 0.75rem 0.5rem;
}

.amz-1 {
  text-align: center;
  flex-basis: 3rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.amz-2 {
  flex-grow: 1;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.amz-3 {
  flex-basis: 2rem;
  flex-shrink: 0;
}

.amz-4 {
  text-align: right;
  display: block;
  flex-basis: 5rem;
  flex-shrink: 0;
  display: none;
}

.m-entry {
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  align-items: center;
  display: flex;
  border: none;
  border-bottom: 1px solid #e2e8f0;
}

.m-entry .amz-1 {
  height: 48px;
  width: 100%;
}

@media screen and (min-width: 599px) {
  .amz-4 {
    display: block;
  }
}

@media screen and (min-width: 499px) {
  .amz-3 {
    flex-basis: 7rem;
  }
}

/* Ebay */
#ebay {
  margin-bottom: 3rem;
}

.eb-header {
  flex-wrap: nowrap;
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #f97316;
  border-bottom: 4px solid #e2e8f0;
  padding: 0 0.75rem 0.5rem;
}

.eb-1 {
  flex-basis: 3rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.eb-2 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  flex-grow: 1;
}

.eb-3 {
  flex-basis: 4rem;
  flex-shrink: 0;
}

.eb-4 {
  flex-basis: 9rem;
  flex-shrink: 0;
  display: none;
}

.eb-5 {
  text-align: center;
  flex-basis: 7rem;
  flex-shrink: 0;
  display: none;
}

.eb-entry {
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  align-items: center;
  display: flex;
  border: none;
  border-bottom: 1px solid #e2e8f0;
}

.eb-entry .eb-1 {
  height: 48px;
}

@media screen and (min-width: 859px) {
  .eb-5 {
    display: block;
  }
}

@media screen and (min-width: 599px) {
  .eb-4 {
    display: block;
  }
  .eb-3 {
    flex-basis: 7rem;
  }
}

/* Ali Express */
#aliexpress {
  margin-bottom: 3rem;
}

.ali-header {
  flex-wrap: nowrap;
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #f97316;
  border-bottom: 4px solid #e2e8f0;
  padding: 0 0.75rem 0.5rem;
}

.ali-1 {
  flex-basis: 3rem;
  flex-shrink: 0;
}

.ali-2 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  flex-grow: 1;
}

.ali-3 {
  flex-basis: 4rem;
  flex-shrink: 0;
}

.ali-4 {
  flex-basis: 4rem;
  flex-shrink: 0;
  display: none;
}

.ali-5 {
  text-align: center;
  flex-basis: 7rem;
  flex-shrink: 0;
  display: none;
}

.ali-entry {
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  align-items: center;
  display: flex;
  border: none;
  border-bottom: 1px solid #e2e8f0;
}

.ali-entry .ali-1 {
  height: 48px;
}

@media screen and (min-width: 859px) {
  .ali-5 {
    display: block;
  }

  .ali-4 {
    flex-basis: 9rem;
  }
}

@media screen and (min-width: 599px) {
  .ali-4 {
    display: block;
  }
  .ali-3 {
    flex-basis: 7rem;
  }
}

/* GoDaddy */
#godaddy {
  margin-bottom: 3rem;
}

.gd-entries {
  flex-wrap: wrap;
  display: flex;
}

.gd-entry {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

.gd-a {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border: none;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

svg.gd-a-a {
  width: 30px;
  font-size: 0.5rem;
  color: #ea580c;
  display: inline;
}

@media screen and (min-width: 599px) {
  .gd-entry {
    width: 50%;
  }
}

@media screen and (min-width: 899px) {
  .gd-entry {
    width: 33.33%;
  }
}

/* CLOUD */
.no-cloud {
  background: #ef4444;
  display: inline-block;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  line-height: 100%;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

/* Keyword Difficulty */
.difficulty {
  display: inline-block;
  min-width: max-content;
  padding: 5px 15px;
  width: 50px;
  border-radius: 5px;
  text-align: center;
}

.undefined.difficulty {
  margin: 0;
  padding: 0;
}

.ks-rd {
  font-size: 2rem;
  color: #ea580c;
  cursor: pointer;
  margin: 0 auto;
}

.ks-rd:hover {
  color: #000;
}

.light-green.difficulty {
  background: lightgreen;
}

.dark-green.difficulty {
  background: darkgreen;
  color: #fff;
}

.yellow.difficulty {
  background: yellow;
}

.orange.difficulty {
  background: orange;
}

.red.difficulty {
  background: red;
  color: #fff;
}

.kd-pop div {
  display: block;
  margin: 5px;
  font-size: 0.9rem;
}

/* Copy to clipboard */
.copy-text-wrap {
  text-align: right;
  font-size: 0.75rem;
  padding: 10px 0 5px 10px;
  cursor: pointer;
  color: #f97316;
}

.copy-text-wrap:hover {
  color: #000;
}

.copy-text-wrap svg {
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

/* Niche Validation Worksheet */
#nvw {
  max-width: 800px;
  margin: 0 auto 30px;
  font-size: 14px;
}

#nvw table {
  width: 100%;
  margin-bottom: 50px;
}

.tbl-header {
  text-transform: uppercase;
  font-size: 12px;
  color: #ea580c;
  font-weight: bold;
  padding-bottom: 2px;
  border-bottom: 4px solid #e2e8f0;
  margin-bottom: 15px;
}

.tbl-header > div {
  display: inline-block;
}

.tbl-entry {
  padding: 10px 0;
  border-bottom: 1px solid #e2e8f0;
}

.tbl-entry > div {
  display: inline-block;
}

.tbl-sep {
  height: 30px;
  display: block;
}

/* 1 */
#nvw th {
  text-transform: uppercase;
  font-size: 12px;
  color: #ea580c;
}

#nvw table td {
  font-size: 14px;
  padding: 15px;
}

#nvw table tr {
  border-bottom: 1px solid #e2e8f0;
}

.nvw-s1 tr td:first-child {
  font-weight: bold;
  color: #ea580c;
  width: 300px;
}

.nvw-2-a {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
}

.nvw-2-b textarea {
  width: 100%;
  border: 1px solid #e2e8f0;
  padding: 20px;
  font-size: 14px;
}

/* 2nd */
.nvw-section {
  margin-bottom: 50px;
}

/* 3rd */
#nvw-3 .tbl-header div:nth-child(1),
#nvw-3 .tbl-entry div:nth-child(1) {
  width: 55%;
}

#nvw-3 .tbl-header div:nth-child(2),
#nvw-3 .tbl-entry div:nth-child(2) {
  width: 15%;
}

#nvw-3 .tbl-header div:nth-child(3),
#nvw-3 .tbl-entry div:nth-child(3) {
  width: 15%;
}

#nvw-3 .tbl-header div:nth-child(4),
#nvw-3 .tbl-entry div:nth-child(4) {
  width: 15%;
}

/* 4th */
#nvw-4 .tbl-header > div,
#nvw-4 .tbl-entry > div {
  width: 12%;
}

#nvw-4 .tbl-header > div:nth-child(1),
#nvw-4 .tbl-entry > div:nth-child(1) {
  width: 40%;
}

.nvw-4c div:first-child {
  text-align: center;
}

.nvw-4a > div {
  display: inline-block;
}

.nvw-4a {
  max-width: 600px;
  margin: 0 auto 30px;
}

.nvw-4a svg {
  font-size: 50px;
  color: #ea580c;
  margin: 0 20px;
}

/* 5th */
#nvw-5 .tbl-header > div,
#nvw-5 .tbl-entry > div {
  width: 30%;
}

#nvw-5 .tbl-header > div:nth-child(1),
#nvw-5 .tbl-entry > div:nth-child(1) {
  width: 70%;
}

/* 6th */
#nvw-6 .tbl-header > div,
#nvw-6 .tbl-entry > div {
  width: 30%;
}

#nvw-6 .tbl-header > div:nth-child(1),
#nvw-6 .tbl-entry > div:nth-child(1) {
  width: 70%;
}

/* 7th */
#nvw-7 .tbl-header > div,
#nvw-7 .tbl-entry > div {
  width: 30%;
}

#nvw-7 .tbl-header > div:nth-child(1),
#nvw-7 .tbl-entry > div:nth-child(1) {
  width: 70%;
}

/* 8th */
#nvw-8 .tbl-header > div,
#nvw-8 .tbl-entry > div {
  width: 50%;
}

#nvw-8 .tbl-header > div:nth-child(1),
#nvw-8 .tbl-entry > div:nth-child(1) {
  width: 50%;
}

/* 9th */
#nvw-9 .tbl-header > div,
#nvw-9 .tbl-entry > div {
  width: 33.33%;
}

/* 10th */
#nvw-10 .tbl-header > div:nth-child(1),
#nvw-10 .tbl-entry > div:nth-child(1) {
  width: 70%;
}

#nvw-10 .tbl-header > div:nth-child(2),
#nvw-10 .tbl-entry > div:nth-child(2) {
  width: 30%;
}

#nvw-10 .tbl-entry span {
  color: #ea580c;
  font-size: 12px;
  padding: 0 10px;
}

/* AMA Page */
.ama-options {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 20px;
  padding: 20px;
  border: 1px dotted #c4cdd9;
  background: #fff;
  z-index: 99;
}

#ama .result-header {
  font-size: 20px;
  line-height: 160%;
  max-width: 800px;
  text-align: left;
  font-weight: normal;
  margin: 0 auto 40px;
  text-transform: math-auto;
}

.sf-5 svg {
  font-size: 20px;
  color: #ea580c;
}

.opt-entry {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 20px;
}

.opt-entry:last-child {
  padding: 0;
}

.opt-entry > div:nth-child(1) {
  width: 150px;
  font-weight: bold;
  flex-shrink: 0;
}

.opt-entry > div:nth-child(2) {
  width: 60%;
  flex-shrink: 0;
  font-size: 14px;
}

.opt-entry > div:nth-child(3) {
  flex-grow: 1;
  text-align: right;
  padding-left: 20px;
}

.ms-entries input,
.ms-entries select {
  border: 1px solid #c8d2dd;
  width: 100%;
  padding: 10px;
}

.regen-btn {
  text-align: center;
  max-width: max-content;
  margin: 30px auto 25px;
  background: #ea580c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.regen-btn {
  text-align: center;
  max-width: max-content;
  margin: 30px auto 25px;
  background: #ea580c;
  color: #fff;
  padding: 15px 25px;
  border-radius: 40px;
  cursor: pointer;
}

.regen-btn svg {
  display: inline-block;
  margin-right: 10px;
}

.regen-btn:hover {
  background: #000;
}

.ama-response {
  border: 1px dotted #c8d2dd;
  padding: 20px;
  white-space: break-spaces;
}

.ama-response pre {
  font-family: "Montserrat";
  line-height: 160%;
}
